import React from 'react';
import styled from '@emotion/styled';

import Layout from '../components/layout';
import SEO from '../components/seo';

import GrowthTeam from '../blocks/contactUsForm/growthTeam';
import WhatHappensNext from '../blocks/contactUsForm/whatHappensNext';
import TempHeader from '../blocks/expertiseHeaderTwo';
import ContactUsForm from '../blocks/contactUsForm';
import member1 from '../images/assets/member01.jpg';
import member2 from '../images/assets/member02.jpg';
const SectionContainer = styled.div`
  max-width: 1024px;
  width: 100%;
  margin: auto;
  font-family: 'DM Sans', sans-serif;
  box-sizing: border-box;
  @media (max-width: 965px) {
    padding: 15px;
  }
  @media (max-width: 735px) {
    padding: 15px;
  }
`;

const subtitle =
  ' Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi in porttitor diam, id ullamcorper eros. Vestibulum vitae massa sem. Praesent aliquet nulla felis.';

const clientEmails = [
  'katy@reloaddigital.co.uk',
  'jess@reloaddigital.co.uk',
  'adam@reloaddigital.co.uk',
];

const teamMembers = [
  {
    src: member1,
    name: 'Joanne Doe',
    email: 'sampleemail@reload.com',
  },
  {
    src: member2,
    name: 'Joanne Doe',
    email: 'sampleemail@reload.com',
  },
];

const whnData = [
  {
    title: 'Discovery Call',
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod. Lorem ipsum dolor sit amet.',
  },
  {
    title: 'Research',
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod. Lorem ipsum dolor sit amet.',
  },
  {
    title: 'Proposal',
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod. Lorem ipsum dolor sit amet.',
  },
  {
    title: "Let's Do It",
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod. Lorem ipsum dolor sit amet.',
  },
];

const ContactUs = () => (
  <Layout logo_theme={'light'} header_items_theme={'light'}>
    <SEO title="Contact us" />
    <SectionContainer>
      {/*<ContactUsForm clientEmails={clientEmails} />
    
    <GrowthTeam teamMembers={teamMembers} />
<WhatHappensNext whnData={whnData} />*/}
    </SectionContainer>
  </Layout>
);

export default ContactUs;
